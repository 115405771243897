import React, { useEffect } from 'react';
import {
  StyleSheet,
  Text,
  View,
  Image,
  ActivityIndicator,
  Dimensions,
} from 'react-native';

// import data from './data';
import Swiper from 'react-native-deck-swiper';
import axios from './axios';
import '@expo/match-media';
import { useMediaQuery } from 'react-responsive';
import {
  useFonts,
  Jost_400Regular,
  Jost_500Medium_Italic,
} from '@expo-google-fonts/jost';
import { IconButton } from 'react-native-paper';
import { Overlay } from 'react-native-elements';
import Modal from 'modal-react-native-web';

const { height, width } = Dimensions.get('window');
const colours = {
  black: '#000000',
  blue: '#0070FF',
  blue_pastel: '#4675db',
  gray: '#777777',
  green_pastel: '#76e2b3',
  magenta: '#915dd1',
  crimson_light: '#ab2929',
  red_pastel: '#ec5e6f',
  ecru: '#eeceac',
  amber: '#f5b748',
  off_white: '#F4F0DB',
  white: '#ffffff',
};

const Card = ({ card }) => {
  return (
    <View style={styles.card}>
      <Image source={{ uri: card.thumbnailUrl }} style={styles.cardImage} />
      <Text style={[styles.text, styles.title]}>{card.title}</Text>
    </View>
  );
};

const swiperRef = React.createRef();

export default function App() {
  const [recipes, setRecipes] = React.useState(null);
  const [index, setIndex] = React.useState(0);
  const [visible, setVisible] = React.useState(false);

  const onSwiped = () => {
    setIndex((currentIndex) => currentIndex + 1);
  };

  const swipedUp = () => {
    onSwiped();
    window.open(
      'https://www.youtube.com/watch?v=' + recipes[index].youtubeUrlId
    );
  };

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get('/get/recipes/random');
      setRecipes(response.data);
      setIndex(0);
    }
    fetchData();
  }, []);

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)',
  });

  const isScreenNarrow = useMediaQuery({ maxWidth: 450 });

  let [fontsLoaded] = useFonts({ Jost_400Regular, Jost_500Medium_Italic });

  const toggleOverlay = () => {
    setVisible(!visible);
  };

  return (
    <View style={styles.pageContainer}>
      <View style={styles.header}>
        <View style={styles.titleLogoContainer}>
          <Text style={styles.pageTitle}>Feast</Text>
          <Image
            source={require('./assets/feast_pizza_logo.png')}
            style={styles.headerLogo}
          />
        </View>
      </View>
      {!recipes ? (
        <ActivityIndicator size='large' />
      ) : (
        <View style={styles.swiperContainer}>
          <Swiper
            //Essential props
            ref={swiperRef}
            cards={recipes}
            cardIndex={index}
            renderCard={(card) => <Card card={card} />}
            //Swipe behaviour
            onSwipedRight={onSwiped}
            onSwipedLeft={onSwiped}
            onSwipedTop={swipedUp}
            onSwipedAll={() => window.location.reload()}
            disableBottomSwipe
            swipeBackCard
            horizontalThreshold={isScreenNarrow ? width * 0.3 : width * 0.25}
            //Stack style props
            stackSeparation={10}
            stackScale={1}
            stackSize={5}
            //Swiper and card style props
            backgroundColor={'transparent'}
            marginTop={isScreenNarrow ? -80 : 10}
            marginBottom={isScreenNarrow ? 50 : 0}
            cardVerticalMargin={isScreenNarrow ? 90 : -60}
            cardHorizontalMargin={isDesktopOrLaptop ? 400 : 10}
            cardStyle={isScreenNarrow ? { height: '70%' } : { height: '110%' }}
            //Card animation props
            animateCardOpacity
            swipeAnimationDuration={400}
            outputRotationRange={['-15deg', '0deg', '15deg']}
            //Overlay label props
            animateOverlayLabelsOpacity
            overlayOpacityHorizontalThreshold={5}
            overlayLabels={{
              left: {
                title: 'NOPE',
                style: {
                  label: {
                    backgroundColor: colours.red_pastel,
                    color: colours.white,
                    fontSize: 24,
                  },
                  wrapper: {
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    justifyContent: 'flex-start',
                    marginTop: '1.5vh',
                    marginLeft: '-2vw',
                  },
                },
              },
              right: {
                title: 'LIKE',
                style: {
                  label: {
                    backgroundColor: colours.green_pastel,
                    color: colours.white,
                    fontSize: 30,
                  },
                  wrapper: {
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    marginTop: '1.5vh',
                    marginLeft: '2vw',
                  },
                },
              },
              top: {
                title: 'SHOW ME!',
                style: {
                  label: {
                    backgroundColor: colours.blue_pastel,
                    color: colours.white,
                    fontSize: 30,
                  },
                  wrapper: {
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    bottom: 80,
                  },
                },
              },
            }}
          />
        </View>
      )}
      <View style={styles.bottomContainer}>
        <View style={styles.bottomButtonsContainer}>
          <IconButton
            icon='refresh'
            size={35}
            color={colours.amber}
            onPress={() =>
              swiperRef.current.swipeBack(() => {
                setIndex((currentIndex) => currentIndex - 1);
              })
            }
            style={StyleSheet.flatten([
              styles.swipeButtons,
              { transform: 'scaleX(-1)' },
            ])}
          />
          <IconButton
            icon='close'
            size={35}
            color={colours.red_pastel}
            onPress={() => swiperRef.current.swipeLeft()}
            style={styles.swipeButtons}
          />
          <IconButton
            icon='heart'
            size={35}
            color={colours.blue_pastel}
            onPress={() => swiperRef.current.swipeTop()}
            style={styles.swipeButtons}
          />
          <IconButton
            icon='check'
            size={35}
            color={colours.green_pastel}
            onPress={() => swiperRef.current.swipeRight()}
            style={styles.swipeButtons}
          />
          <IconButton
            icon='information-outline'
            size={35}
            color={colours.magenta}
            onPress={() => toggleOverlay()}
            style={styles.swipeButtons}
          />
        </View>
      </View>
      {recipes && (
        <Overlay
          ModalComponent={Modal}
          isVisible={visible}
          onBackdropPress={toggleOverlay}
          animationType={'fade'}
        >
          <View
            style={
              isScreenNarrow ? styles.mobileInfoContainer : styles.infoContainer
            }
          >
            <Text style={styles.infoText}>
              Swipe through YouTube recipe thumbnails until one takes your
              fancy!{'\n'}
            </Text>
            <Text style={styles.infoText}>
              Swipe right or press the tick for the ones you like. Swipe left or
              press the cross for the ones you don't.{'\n'}
            </Text>
            <Text style={styles.infoText}>
              If you really like a recipe and want to see more, swipe up or
              press the heart: it'll take you to the video showing you how to
              make it!{'\n'}
            </Text>
            <Text style={styles.infoText}>
              Over time, Feast will get better at knowing your preferences and
              will be able to show you more things that you'll like.{'\n'}
            </Text>
            <Text style={styles.infoText}>
              If you swipe on recipe by accident, hit the refresh button to
              bring the card back.{'\n'}
            </Text>
            <Text style={styles.infoText}>
              Filtering cards by meals (dinner, dessert etc.) and viewing your
              liked recipes are just some of the features to come...{'\n'}
            </Text>
            <Text style={styles.infoText}>In the meantime: Let's feast!</Text>
          </View>
        </Overlay>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  pageContainer: {
    flex: 1,
    backgroundColor: colours.white,
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  header: {
    flex: 0.085,
    height: '100%',
    marginTop: 10,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  titleLogoContainer: {
    width: 130,
    height: '100%',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
  },
  pageTitle: {
    flex: 0.5,
    fontFamily: 'Jost_500Medium_Italic',
    fontSize: 35,
  },
  headerLogo: {
    flex: 0.5,
    resizeMode: 'contain',
    alignSelf: 'stretch',
  },
  swiperContainer: {
    flex: 0.665,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    flex: 1,
    borderRadius: 20,
    shadowRadius: 25,
    shadowColor: colours.black,
    shadowOpacity: 0.5,
    shadowOffset: { width: 0, height: 0 },
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colours.white,
    zIndex: 1,
    elevation: 1,
    borderWidth: 2,
  },
  cardImage: {
    //Image dimensions are 1280 x 720
    width: '95%',
    flex: 0.95,
    resizeMode: 'contain',
  },
  bottomContainer: {
    flex: 0.1,
    alignItems: 'center',
    justifyContent: 'space-evenly',
    zIndex: -1,
    elevation: -1,
  },
  cardDetails: {
    alignItems: 'center',
  },
  text: {
    fontFamily: 'Jost_400Regular',
    marginTop: '1%',
    marginLeft: 5,
    marginRight: 5,
  },
  title: {
    fontSize: 20,
    marginBottom: 10,
    color: colours.black,
    textAlign: 'center',
  },
  bottomButtonsContainer: {
    width: '100vw',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    position: 'fixed',
    bottom: 30,
    left: 5,
  },
  swipeButtons: {
    shadowOffset: { width: 0, height: 7.5 },
    shadowRadius: 15,
    shadowOpacity: 0.3,
  },
  infoContainer: {
    height: '50vh',
    maxWidth: '80vw',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  mobileInfoContainer: {
    height: '70vh',
    maxWidth: '80vw',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  infoText: {
    fontFamily: 'Jost_400Regular',
    fontSize: 15,
    marginTop: '1%',
    marginLeft: 5,
    marginRight: 5,
  },
});
